<template>
  
</template>

<script>
export default {
    async mounted() {
        const params = this.$route.query
        window.history.replaceState({}, document.title, window.location.pathname);
        await this.callbackStreamlabs(params)
        window.location.replace('/studio/integrations?info=linked&platform=streamlabs')
    },
}
</script>

<style>

</style>